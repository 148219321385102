<script setup lang="ts">
const loading = ref(true);
const utilsStore = useUtilsDataStore();
const initialized = ref(utilsStore.initialized);

onMounted(() => {
	if (initialized.value) {
		loading.value = false;
	}
	watch(initialized, (newVal) => {
		if (newVal) {
			loading.value = false;
		}
	});
});
</script>

<template>
	<div>
		<transition name="fade">
			<SplashScreen v-if="loading" />
		</transition>
		<div class="wrapper">
			<main>
				<slot />
			</main>
		</div>
	</div>
</template>

<style scoped lang="css">
.wrapper {
	@apply min-h-screen flex flex-col bg-white dark:bg-neutral-950 text-neutral-900 dark:text-neutral-50;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
